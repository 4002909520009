@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.custom-big-play-button {
  &.video-react-big-play-button {
    visibility: hidden;
  }
}

.error {
  .react-tags {
    position: relative;
    padding: 6px 0 0 6px;

    border-radius: 6px;
    --tw-border-opacity: 1;
    border-width: 2px;
    border-style: solid;
    width: 100%;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;

    /* clicking anywhere will focus the input */
    cursor: text;
  }
}
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;

  border-radius: 6px;
  --tw-border-opacity: 1;
  border-width: 2px;
  border-style: solid;
  width: 100%;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}
input:checked ~ .dot-bg {
  background-color: #4aa82d;
}

.shadow-header {
  background: #fefefe;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
}

.setting-main-card {
  background: #ffffff;
  box-shadow: 0px 5px 44px rgba(0, 0, 0, 0.04);
  border: 1px solid;

  border-image-source: linear-gradient(
    232.42deg,
    rgba(231, 231, 231, 0.156) 6.52%,
    rgba(107, 107, 107, 0.102) 92.4%
  );
}

.setting-info-card {
  box-shadow: 0px 5px 38px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 10px;
}

.button-active {
  background: rgba(17, 137, 247, 0.1);
  border: 1px solid #1189f7;
  box-sizing: border-box;
  border-radius: 8px;
}

.gradient-background-t {
  background: linear-gradient(180deg, rgba(17, 29, 27, 0.7) 0%, #000000 100%);
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 10px 10px;
  transform: rotate(-180deg);
}

.landing-page-header-title-text {
  font-size: 44px;
  letter-spacing: 3px;
  font-family: Quicksand;
  font-weight: bold;
  color: #222222;
  line-height: 70px;
}

.rounded-dropdown-styling {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.landing-page-header-sub-title-text {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  /* or 160% */

  /* Bodytext */

  color: #868686;
}

.gradient-background {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.56) 0%,
    rgba(0, 0, 0, 0.91) 37.41%,
    #000000 100%
  );
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 10px 10px;
}

.web3modal-modal-lightbox {
  z-index: 90 !important;
}

.exclusive-join-now-background {
  background: linear-gradient(163.11deg, #f9fff8 31.28%, #ffffff 68.62%);
}

.create-nft-form-background {
  background: linear-gradient(
    200.99deg,
    rgba(255, 255, 255, 0.27) 0%,
    rgba(255, 255, 255, 0.48) 98.88%
  );
  border: 0.4px solid rgba(205, 208, 204, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 5px 44px rgba(120, 120, 120, 0.04);
  border-radius: 10px;
}

.card-activity-background {
  background: #ffffff;
  box-shadow: 0px 5px 44px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

.activity-background {
  background: linear-gradient(
    200.99deg,
    rgba(255, 255, 255, 0.45) 0%,
    rgba(255, 255, 255, 0.8) 98.88%
  );
  border: 1px solid;

  border-image-source: linear-gradient(
    232.42deg,
    rgba(231, 231, 231, 0.208) 6.52%,
    rgba(107, 107, 107, 0.136) 92.4%
  );
  box-shadow: 0px 4px 14px 0px #0000000a;
}

.dropdown-navigator-background {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
}

.all-asset-background {
  background: linear-gradient(
    180deg,
    rgba(180, 255, 157, 0.1) 0%,
    rgba(228, 254, 220, 0.1) 100%
  );
}

.verified-background {
  background: linear-gradient(
    180deg,
    rgba(131, 230, 101, 0.06) 0%,
    rgba(74, 168, 45, 0) 100%
  );
  border-radius: 5px;
  // transform: matrix(1, 0, 0, -1, 0, 0);
  transform: rotate(180deg);
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.create-asset-background {
  background: linear-gradient(
    269.5deg,
    rgba(249, 253, 248, 0.38) 57.34%,
    rgba(255, 255, 255, 0.89) 121.41%
  );
  box-shadow: 12px 14px 14px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
}

.landing-page-card-background {
  background: #ffffff;
  box-shadow: 0px 4px 44px rgba(138, 138, 138, 0.1);
}

.setting-sidebar-background {
  background: #ffffff;
  box-shadow: 4px 5px 44px rgba(120, 120, 120, 0.08);
}

.landing-page-browse-by-background {
  background: linear-gradient(
    180deg,
    rgba(242, 249, 240, 0.27) 0%,
    rgba(74, 168, 45, 0.01) 100%
  );
}

.my-collection-header-background {
  background: #f9fdf8;
  box-shadow: 12px 14px 18px rgba(0, 0, 0, 0.03);
}

.my-collection-background {
  background: #f8f8f8;
  box-shadow: 12px 14px 14px rgba(0, 0, 0, 0.01);
}

.collection-header-background {
  background: #f9fdf8;
  box-shadow: 12px 14px 18px rgba(0, 0, 0, 0.03);
}

.collection-background {
  background: #f8f8f8;
  box-shadow: 12px 14px 14px rgba(0, 0, 0, 0.01);
}

.detail-asset-background {
  background: linear-gradient(
    250.93deg,
    rgba(249, 253, 248, 0.6) 66.99%,
    rgba(255, 255, 255, 0.89) 74.52%
  );
  box-shadow: 12px 14px 14px rgba(0, 0, 0, 0.01);
  border-radius: 10px;
}
.splitter-background {
  background: linear-gradient(
    162.96deg,
    #f8fff7 -2.21%,
    rgba(255, 255, 255, 0) 88.27%
  );
}

/* .table-background {
  background: linear-gradient(162.96deg, #f8fff7 -2.21%, rgba(255, 255, 255, 0) 88.27%);
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.03));
  border-radius: 10px;
} */

.inactive-picker-background {
  background: linear-gradient(
    105.01deg,
    rgba(211, 251, 199, 0.3) 19.06%,
    rgba(174, 216, 162, 0.285) 50.64%,
    rgba(203, 255, 187, 0) 149.43%
  );
}

.circle-picker-background {
  background: linear-gradient(
    200.56deg,
    #dfeeda 3.41%,
    rgba(104, 189, 80, 0.5) 86.36%
  );
}

.main-table {
  .rdt_Table {
    background: #fafdff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.03));
    border-radius: 10px;

    .rdt_TableBody {
      background: transparent;
      .rdt_TableRow {
        background: transparent;
        .rdt_TableCell {
          background: transparent;
        }
      }
    }
    .rdt_TableHead {
      background: transparent;
      .rdt_TableHeadRow {
        background: transparent;
        .rdt_TableCol {
          background: transparent;
        }
        .rdt_TableCol_Sortable {
          background: transparent;
        }
      }
    }
  }
}

.white-table {
  .rdt_Table {
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.03));
    border-radius: 10px;

    .rdt_TableBody {
      background: transparent;
      .rdt_TableRow {
        background: transparent;
        .rdt_TableCell {
          background: transparent;
        }
      }
    }
    .rdt_TableHead {
      background: transparent;
      .rdt_TableHeadRow {
        background: transparent;
        .rdt_TableCol {
          background: transparent;
        }
        .rdt_TableCol_Sortable {
          background: transparent;
        }
      }
    }
  }
}

.slider-demo .p-slider-horizontal,
.slider-demo .p-inputtext {
  width: 14rem;
}
.p-slider {
  & .p-component .p-slider-horizontal {
  }
  .p-slider-range {
    background: #4aa82d !important;
    opacity: 1 !important;
  }
  & .p-slider-handle {
    background: #4aa82d !important;
    border: 2px solid #4aa82d !important;
    opacity: 1 !important;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.slider-demo .p-slider-vertical {
  height: 14rem;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
